import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  allowOnlyNumbers(e) {
    const key = e.which || e.keyCode;
  
    if (
      (key < 48 || key > 57) && // Digits 0-9
      ![8, 9, 13, 27, 37, 39, 46].includes(key)
    ) {
      e.preventDefault();
      return;
    }
  }

  connect(){
    this.element.addEventListener('keydown', this.allowOnlyNumbers, false);
  }

  disconnect(){
    this.element.removeEventListener('keydown', this.allowOnlyNumbers, false);
  }
}
