import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['field', 'message', 'submitButton'];

  connect() {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      this.element.addEventListener(eventName, this.preventDefaults, false);
    });
    ['dragenter', 'dragover'].forEach((eventName) => {
      this.element.addEventListener(eventName, this.highlight.bind(this), false);
    });
    ['dragleave', 'drop'].forEach((eventName) => {
      this.element.addEventListener(eventName, this.unhighlight.bind(this), false);
    });

    this.element.addEventListener('drop', this.handleDrop.bind(this), false);
    this.fieldTarget.addEventListener('change', this.handleFileTargetChange.bind(this), false);
  }

  disconnect() {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      this.element.removeEventListener(eventName, this.preventDefaults, false);
    });
    ['dragenter', 'dragover'].forEach((eventName) => {
      this.element.removeEventListener(eventName, this.highlight.bind(this), false);
    });
    ['dragleave', 'drop'].forEach((eventName) => {
      this.element.removeEventListener(eventName, this.unhighlight.bind(this), false);
    });

    this.element.removeEventListener('drop', this.handleDrop.bind(this), false);
    this.fieldTarget.removeEventListener('change', this.handleFileTargetChange.bind(this), false);
  }

  preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  highlight(e) {
    this.element.classList.add('bg-red-200');
    this.element.classList.remove('bg-secondary-50');
  }

  unhighlight(e) {
    this.element.classList.remove('bg-red-200');
    this.element.classList.add('bg-secondary-50');
  }

  handleFileTargetChange(e) {
    this.messageTarget.innerHTML = this.fieldTarget.files[0].name || this.fieldTarget.dataset.uploadFileLocale;
    this.submitButtonTarget.click();
  }

  handleDrop(e) {
    let dt = e.dataTransfer;
    let files = dt.files;
    if (files.length > 1) {
      this.messageTarget.innerHTML = this.messageTarget.dataset.onlyOneFileLocale;
    } else {
      this.fieldTarget.files = files;
      this.handleFileTargetChange(e);
    }
  }
}
