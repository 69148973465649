import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fileInput", "form"]

  selectFile() {
    this.fileInputTarget.click()
  }

  fileChanged() {
    this.formTarget.submit()
  }
}
