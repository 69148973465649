/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails
window.Turbo = Turbo

// Custom confirm dialog
Turbo.setConfirmMethod((message) => {
  // Select the dialog element
  const confirmDialog = document.getElementById("turbo-confirm");
  // Set message
  confirmDialog.querySelector("#confirm-message").textContent = message;

  // Show the dialog
  confirmDialog.showModal();

  // Return a promise that resolves based on the user clicking "confirm" or "cancel"
  return new Promise((resolve, reject) => {
    confirmDialog.addEventListener("close", () => {
      resolve(confirmDialog.returnValue == "confirm");
    }, { once: true });
  });
});

require("@rails/activestorage").start()
import "@rails/actiontext"

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"

// Jumpstart Pro & other Functionality
import "./src/**/*"
require("local-time").start()

// Start Rails UJS
Rails.start()
