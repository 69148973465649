import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("connected");
    // append a hidden field element after this.element
    // <input type="text" name="allowance" id="allowance" value="" class="form-control" placeholder="$">
    const input = this.element;

    // Input hidden field must be create because rails doesn't send error messages for fields that are not in the form or are hidden
    this.inputHidden = document.createElement("input");
    this.inputHidden.type = "hidden";
    this.inputHidden.name = input.name;
    this.inputHidden.id = input.id;

    // validate if input has value and format it and set it to the hidden field
    this.keyup();

    // append the hidden field after the input
    input.insertAdjacentElement("afterend", this.inputHidden);

    // add event listener to keyup on input to allow only numbers and format currency
    input.addEventListener("keyup", this.keyup.bind(this), false);
  }

  disconnect() {
    console.log("disconnected");

    // remove event listener to keyup on input to allow only numbers
    this.element.removeEventListener("keyup", this.keyup.bind(this), false);

    // remove the hidden field
    this.inputHidden.remove();
  }

  keyup(e) {
    const input = e?.target || this.element;

    this.allowOnlyNumbers(e);

    // save the cursor position
    const originalLength = input.value.length;
    const initialSelectionStart = input.selectionStart;

    // format the value
    this.inputHidden.value = this.fromStringToNumber(input.value);
    input.value = this.formatCurrency(this.inputHidden.value);

    // save the cursor position after formatting
    const newLength = input.value.length;
    const selectionStartOffset = newLength - originalLength;

    // set the cursor position to the right place
    input.selectionStart = initialSelectionStart + selectionStartOffset;
  }

  // remove all non numeric characters and return a number
  fromStringToNumber(value) {
    // remove all non numeric characters
    const new_value = value.replace(/\D/g, "");

    if (new_value && new_value !== "") {
      return parseInt(new_value);
    }

    return 0;
  }

  // format a number to currency
  formatCurrency(value) {
    let new_value;
    if (value.length === 1) {
      new_value = "0,0" + value;
    } else if (value.length === 2) {
      new_value = "0," + value;
    } else if (value.length > 2) {
      new_value = value.slice(0, -2).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "," + value.slice(-2);
    } else {
      new_value = "0,00";
    }
    return "$ " + new_value;
  }

  // accept only numbers and backspace
  allowOnlyNumbers(e){
    if(e && e.which !== 8 && (e.which < 48 || e.which > 57)){
      e.preventDefault();
      return;
    }
  }
}
