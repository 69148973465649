import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    data: String,
  };

  static targets = ["code"];

  connect() {
    this.addTable(JSON.parse(this.dataValue), this.element);
  }

  createElement(type) {
    const element = document.createElement(type);
    switch (type) {
      case 'table':
        element.className = 'w-full text-sm text-left text-gray-500 ';
        break;
      case 'thead':
        element.className = 'text-xs text-gray-700 uppercase bg-gray-50  ';
        break;
      case 'tbody':
      case 'tr':
        element.className = 'bg-white border-b  ';
        break;
      case 'th':
      case 'td':
        element.className = 'px-6 py-4';
        break;
    }
    return element;
  }

  addCellToRow(row, value) {
    const cell = this.createElement('td');
    if (Array.isArray(value)) {
      this.addTable(value, cell);
    } else if (typeof value === 'object') {
      this.addObject(Object.values(value), cell);
    } else {
      cell.innerHTML = value ?? "";
    }
    row.appendChild(cell);
  }

  addTable(list, appendObj) {
    const table = this.createElement('table');
    const thead = this.createElement('thead');
    const tbody = this.createElement('tbody');
    const columns = this.addAllColumnHeaders(list, thead);
    
    list.forEach(item => {
      const row = this.createElement('tr');
      columns.forEach(col => this.addCellToRow(row, item[col]));
      tbody.appendChild(row);
    });

    table.appendChild(thead);
    table.appendChild(tbody);
    appendObj.appendChild(table);
  }

  addObject(list, appendObj) {
    list.forEach(item => {
      const row = this.createElement('tr');
      this.addCellToRow(row, item);
      appendObj.appendChild(row);
    });
  }

  addAllColumnHeaders(list, appendObj) {
    const columnSet = [];
    const headerRow = this.createElement('tr');
    list.forEach(item => {
      for (const key in item) {
        if (!columnSet.includes(key)) {
          columnSet.push(key);
          const header = this.createElement('th');
          header.innerHTML = key;
          headerRow.appendChild(header);
        }
      }
    });
    appendObj.appendChild(headerRow);
    return columnSet;
  }
}