import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sdk-integration"
export default class extends Controller {
  static targets = ["completeMessage", "legalRepresentativeInfo"]

  // Show the next button when we are skipping metamap
  connect(){
    const completedStepElement = this.element.querySelector("[data-completed-step]");
    const isCompleted = completedStepElement.getAttribute("data-completed-step") === "true";

    if (isCompleted) {
        this.completeMessageTarget.classList.remove('hidden');
    }else{
      this.legalRepresentativeInfoTarget.classList.remove('hidden');
    }
  }

  //Only when the user finishes the whole verification process, the next button is enabled.
  next() {
    const button = this.element.querySelector("#mati-button");
    // setup callbacks
    button.addEventListener("mati:loaded", ({ detail }) => {
      console.log("loaded payload", detail);
    });
    button.addEventListener("mati:userFinishedSdk", ({ detail }) => {
      console.log("finished payload", detail);
      this.completeMessageTarget.classList.remove('hidden');
      this.legalRepresentativeInfoTarget.classList.add('hidden');
    });
    button.addEventListener("mati:exitedSdk", ({ detail }) => {
      console.log("exit payload", detail);
    });
  }

}
