import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['loader', 'payment'];
  static values = { url: String}

  connect() {
    this.load()
    this.startRefreshing()
  }

  disconnect() {
    this.stopRefreshing()
  }

  load() {
    //Make request to the url using timeout to simulate a delay in response
    const response = fetch(this.urlValue, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      if(data.status !== "created" || data.status !== "pending"){
        this.stopRefreshing()
      }
    })
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, 5000)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }
}
