// this controller allows us to change the action of a form element.
// the idea is that a user will be able to toggle where to send data depending
// on a checkbox or radio button in the form.
// We are using it in the new card form, where a user is able to create physical
// or virtual cards.
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  change(e){
    let url = e.target.dataset.url;
    this.element.action = url;
  }
}
