import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['column', 'button'];

  connect() {
    this.show = false;
  }

  toggleShow() {
    this.columnTargets.forEach((column, index) => {
      if (index > 1) {
        if (!this.show) {
          column.classList.remove('hidden');
        } else {
          column.classList.add('hidden');
        }
      }
    });

    this.button.innerHTML = this.show ? this.button.dataset.showMoreLocale : this.button.dataset.showLessLocale;
    this.show = !this.show;
  }

  get button() {
    return this.buttonTarget;
  }
}
