import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["info", "action", "parent", "advice", "source"]

  select(event) {
    event.preventDefault()
    this.hideAll()
    const name = event.currentTarget.dataset.name // get the value of data-name attribute
    const infoElement = this.infoTargets.find(element => element.dataset.name === name) // find the right info element based on the data-name attribute
    this.hideElement(infoElement)
    const actionElement = this.actionTargets.find(element => element.dataset.name === name) // find the right info element based on the data-name attribute
    this.hideElement(actionElement)
  }

  hideElement(element) {
    if (element) element.classList.remove('hidden')
  }

  hideAll() {
    this.infoTargets.forEach(element => element.classList.add("hidden"))
    this.actionTargets.forEach(element => element.classList.add("hidden"))
  }

  setParentTransactionId(event) {
    event.preventDefault()
    const transactionId = event.currentTarget.dataset.transactionId
    this.parentTarget.value = transactionId
  }

  setAdviceId(event) {
    event.preventDefault()
    const adviceId = event.currentTarget.dataset.adviceId
    this.adviceTargets.forEach(target => {
      target.value = adviceId;
    });
  }

  copy() {
    navigator.clipboard.writeText(this.sourceTarget.value)
  }
}
