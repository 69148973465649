import { Controller } from "@hotwired/stimulus"
import { toggle } from 'el-transition';

// this controller makes use of the el-transition functionality
// that allows to add transitions based on a class name.
// https://github.com/mmccall10/el-transition#transition-name-option
export default class extends Controller {
  static targets = ['container', 'offCanvas', 'overlay'];

  toggle() {
    if (this.containerTarget.classList.contains('hidden')) {
      toggle(this.containerTarget);
    } else {
      setTimeout(() => {
        toggle(this.containerTarget);
      }, 300);
    }

    toggle(this.offCanvasTarget, 'sidebar');
    toggle(this.overlayTarget, 'overlay');
  }
}
