import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hiddenField", "textField", "submitButton"];

  // tiggered when the user pastes the code on the first input.
  paste(event) {
    event.preventDefault();
    let data = (event.clipboardData || window.clipboardData).getData("text");
    data = data.replaceAll(/[^0-9]/gi, "");

    if(data.length !== 4) return
    this.fillAll(data);
    this.submitButtonTarget.click();
  }

  // triggered when the user is inputing text on any of the fields. Prevents
  // input of non digit chars. And also, handles moving to the next input after
  // value is changed.
  textInput(event) {
    // Checking for Backspace.
    if (event.keyCode === 8 || event.keyCode === 46) {
      event.target.previousElementSibling?.select();
    } else {
      let data = event.target.value.replaceAll(/[^0-9]/gi, "")[
        event.target.value.length - 1
      ];
      if (data !== "" && data != undefined) {
        event.target.value = data;
        event.target.nextElementSibling.select();
      } else {
        event.target.value = "";
      }
    }
    this.isFilled();
  }

  fillAll(code) {
    let [i1, i2, i3, i4] = this.textFieldTargets;
    i1.value = code[0];
    i2.value = code[1];
    i3.value = code[2];
    i4.value = code[3];
    this.isFilled();
  }

  // checks whether all the inputs are filled to remove the styling on the submit
  // button. It also, triggers the click to make a better US.
  isFilled() {
    const code = this.readInputs();
    if (code.length === 4) {
      this.submitButtonTarget.classList.add("bg-primary");
      this.submitButtonTarget.classList.remove(
        "pointer-events-none",
        "bg-secondary-100"
      );
    } else {
      this.submitButtonTarget.classList.remove("bg-primary");
      this.submitButtonTarget.classList.add(
        "pointer-events-none",
        "bg-secondary-100"
      );
    }
  }

  getCode() {
    const code = this.readInputs();
    this.hiddenFieldTarget.value = code;
  }

  readInputs() {
    let code = [];
    const inputElements = this.textFieldTargets;
    inputElements.forEach((input) => {
      code.push(input.value);
    });
    return code.join("");
  }
}
