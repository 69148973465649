import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // This function is automatically called when the controller instance is connected to the DOM
  connect() {
    console.log("Scroll controller connected.");

    // Options for the IntersectionObserver
    const options = {
      // Set up a threshold for every 1/30th of the element's visibility
      threshold: Array.from({ length: 31 }, (_, i) => i / 30),
      // Alternative threshold options could be uncommented and used
      // threshold: [0,0.25, 0.5, 0.75, 1],
    };

    // Create a new IntersectionObserver that calls handleIntersect when the thresholds are crossed
    // The bind method is used to ensure 'this' inside handleIntersect refers to the controller instance
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), options);

    // Observe all sections that have an `id` attribute
    this.element.querySelectorAll("section[id]").forEach((section) => {
      // Save each section's height as a data attribute
      section.dataset.offsetHeight = section.offsetHeight;
      // Begin observing the section
      this.observer.observe(section);
    });
  }

  // This function is automatically called when the controller instance is disconnected from the DOM
  disconnect() {
    // Stop observing all sections when the controller is disconnected
    this.element.querySelectorAll("section[id]").forEach((section) => {
      this.observer.unobserve(section);
    });
  }

  // This function is called by the IntersectionObserver whenever a section crosses a visibility threshold
  handleIntersect(entries) {
    // Get a NodeList of the sections being observed
    let panels = this.element.querySelectorAll("section[id]");

    // For each entry provided by the IntersectionObserver
    for (const entry of entries) {
      const target = entry.target;

      // Save the intersection ratio and intersection status as data attributes on the target
      // target.dataset.intersectionRatio = entry.intersectionRatio;
      // target.dataset.isIntersecting = entry.isIntersecting;
      // Calculate and save the percentage of the target's visibility
      target.dataset.percentageVisible = parseInt(target.dataset.offsetHeight) * entry.intersectionRatio;
    }

    // Initialize variables to track the section with the largest visible area
    let maxSize = 0;
    let maxSection = null;

    // For each section
    panels.forEach((panel) => {
      // Remove the border classes
      panel.classList.remove("border-2", "border-sky-500");
      document.querySelectorAll(`a.registration-form-nav-item[href="#${panel.getAttribute("id")}"]`).forEach((anchor) => {
        anchor.classList.remove("active");
      });

      // Get the percentage of the panel that is visible
      const sectionSize = parseInt(panel.dataset.percentageVisible);

      // If this panel has a larger visible area than the current largest, update the largest
      if (sectionSize > maxSize) {
        maxSize = sectionSize;
        maxSection = panel;
      }

      // Add the border classes to the section with the largest visible area
      if(maxSection){
        maxSection.classList.add("border-2", "border-sky-500");
        document.querySelectorAll(`a.registration-form-nav-item[href="#${maxSection.getAttribute("id")}"]`).forEach((anchor) => {
          anchor.classList.add("active");
        });
      }
    });
  }
}