import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["filter", "item"];

  connect() {
  }

  // keyup method that will filter the target items based on the data attribute value on the input element
  keyup(e) {
    this.itemTargets.forEach((item) => {
      if(item.dataset.itemValue.match(this.filterTarget.value) !== null) {
        item.classList.remove("hidden")
      } else {
        item.classList.add("hidden")
      }
    })
  }
}
