import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "submitButton"]

  connect() {
    this.checkboxTarget.addEventListener('change', () => {
        this.submitButtonTarget.click();
    });
  }
}