import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["labelSwitch", "statusField", "fieldData"]

  connect(){
    console.log("connected with switch_controller")
    const isChecked = this.statusFieldTarget.checked
    const field_data_value = this.fieldDataTarget.value

    this.labelSwitchTarget.classList.toggle("active", isChecked)
    this.statusFieldTarget.setAttribute("aria-checked", isChecked)
    // Inactive temporary while field reviews list is fixed.
    // if (field_data_value === "" || field_data_value === null)
    // {
    //   console.log("hidden")

    //   this.labelSwitchTarget.classList.toggle('hidden');

    // }
  }
  switch() {
    const isChecked = this.statusFieldTarget.checked
    this.labelSwitchTarget.classList.toggle("active", isChecked)
    this.statusFieldTarget.setAttribute("aria-checked", isChecked)
    this.element.querySelector("form").requestSubmit();
  }
}
