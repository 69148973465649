import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selectedPeople","clearFilter"];
 
  connect() {
    console.log("person filter controller connected")
  }

  setSelectedPeople(evt) {
    console.log("setSelectedPeople")
    console.log("setSelectedPeople",evt.detail)
    this.selectedPeopleTarget.value = JSON.stringify(evt.detail);
  }
}