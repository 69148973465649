import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets= ["checkbox", "textField", "hiddenTextField", "streetName", "textFieldLabel"]

  connect() {
    const initialCheckedStatus = ( !(this.textFieldTargets.map(x => x.value != "").includes(true)) && this.streetNameTarget.value != "" )
    this.checkboxTarget.checked = initialCheckedStatus
    this.toggleTextFields()
  }

  toggleTextFields() {
    const checked = this.checkboxTarget.checked
    this.toggleLabelRequiredTextFields(checked)
    this.toggleEnableTextFields(checked)
    this.toggleEnableHiddenTextFields(checked)
  }

  toggleLabelRequiredTextFields(enable) {
    this.textFieldLabelTargets.map(x => {
      if (enable == true) {
        x.classList.value = "label"
      } else {
        x.classList.value = "label label--required"
      }
    })
  }

  toggleEnableTextFields(enable) {
    this.textFieldTargets.map(x => {
      if (enable == true) { x.value = "" }
      x.disabled = enable
    })
  }

  toggleEnableHiddenTextFields(enable) {
    this.hiddenTextFieldTargets.map(x => {
      x.value = ""
      x.disabled = !enable
    })
  }
}
