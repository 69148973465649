import { Controller } from "@hotwired/stimulus";
import { enter } from "el-transition";

export default class extends Controller {
  static targets = [
    "imgUploader",
    "btnSubmit",
    "textInputs",
    "newPasswordInput",
    "currentPasswordInput",
    "confirmPasswordInput",
    "validationPassword",
    "showImage",
    "imageCapitalizedKey"
  ];

  onChangeFile() {
    if (this.imgUploaderTarget.files.length >= 1) {
      const [file] = this.imgUploaderTarget.files;
      let img = document.createElement('img')
      img.src = URL.createObjectURL(file)
      img.classList.add("rounded-full", "h-20", "w-20")
      this.imageCapitalizedKeyTarget.innerHTML = "";
      this.imageCapitalizedKeyTarget.appendChild(img)
    }
  }

  onTypeConfirmationPassword() {
    if (
      this.newPasswordInputTarget.value.length >= 6 &&
      this.confirmPasswordInputTarget.value !==
        this.newPasswordInputTarget.value
    ) {
      this.validationPasswordTarget.classList.remove("hidden");
    } else {
      this.validationPasswordTarget.classList.add("hidden");
    }
  }
}
