import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="legal-representative-info"
export default class extends Controller {
  static targets = ["email", "checkbox"];

  connect(){
    if(this.checkboxTarget.checked){
      this.emailTarget.classList.add("hidden");
    }else{
      this.emailTarget.classList.remove("hidden");
    }
  }

  toggle(e){
    if(e.target.checked){
      this.emailTarget.classList.add("hidden");
    }else{
      this.emailTarget.classList.remove("hidden");
    }
  }
}
