import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['checkbox']
  static values = { payload: String, prefix: String }

  connect() {
    this.toggleCheckbox()
  }

  toggleCheckbox() {
    if (this.checkboxTarget.checked) {
      this.triggerEvent('CheckboxSelected', this.payloadValue)
    } else {
      this.triggerEvent('CheckboxUnselected', this.payloadValue)
    }
  }

  markSelected() {
    this.checkboxTarget.checked = true
    this.toggleCheckbox();
  }

  markUnselected() {
    this.checkboxTarget.checked = false
  }

  triggerEvent(eventName, data) {
    const prefix = this.prefixValue
    const event = new CustomEvent(`${prefix}${eventName}`, { detail: data })
    document.dispatchEvent(event)
  }
}