import { Controller } from "@hotwired/stimulus";
import Flatpickr from 'stimulus-flatpickr'
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { english } from "flatpickr/dist/l10n/default.js";

export default class extends Flatpickr {
  static outlets = ["filter-form"]
  static targets = ["clearAll", "container", "defaultStartDate", "defaultEndDate","preferredLanguage"]
  
  locales = {
    "es-CO": Spanish,
    "en": english
  };
 
  connect() {
    this.config = {
      mode: "range",
      maxDate: Date.now(),
      defaultDate: [
        Date.parse(this.defaultStartDateTarget.value + "T00:00:00"),
        Date.parse(this.defaultEndDateTarget.value + "T00:00:00")
      ],
      inline: true,
      appendTo: this.containerTarget,
      dateFormat: "d-M-Y", // Change date format here
      locale: this.getLocale()
    };
    super.connect();
  }

  close(selectedDates, dateStr, instance) {
    const formattedDates = selectedDates.map(date => {
      return date.toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).toLowerCase();
    });
    this.defaultStartDateTarget.value = this.checkDateValue(formattedDates[0]);
    this.defaultEndDateTarget.value = this.checkDateValue(formattedDates[1]);
  }

  getLocale() {
    const preferredLanguage = this.preferredLanguageTarget.value;
    return this.locales[preferredLanguage] || english;
  }
  
  checkDateValue(value){
    const date = Date.parse(value);
    if (isNaN(date)) {
      console.error('Invalid date:', value);
      return "";
    }
    return value;
  }

  clearAll(evt) {
    evt.preventDefault();
    this.fp.clear();
    const formattedDates = this.fp.selectedDates.map(date => {
      return date.toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).toLowerCase();
    });
    this.defaultStartDateTarget.value = this.checkDateValue(formattedDates[0]);
    this.defaultEndDateTarget.value = this.checkDateValue(formattedDates[1]);
    // outlets do not work properly with this controller. So while that is fixed
    // we will use the regular way to get a controller from an element
    if (this.hasFilterFormOutlet) {
      this.filterFormOutlet.submit(evt);
    }else{
      const filterForm = this.element.closest("[data-controller='filter-form']");
      const filterFormController = this.application.getControllerForElementAndIdentifier(filterForm, 'filter-form')
      filterFormController.submit(evt);
    }
  }
}
