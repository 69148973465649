import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['bar', 'container'];

  onScroll() {
    if (this.containerTarget.scrollTop > 10) {
      this.barTarget.classList.add('shadow');
    } else {
      this.barTarget.classList.remove('shadow');
    }
  }
}
