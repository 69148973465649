import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["selectionCount", "hideable"]
  static values = { prefix: String, payload: String, name: String }

  connect() {
    enter(this.element, "overlay");
    this.triggerEvent("RequestCount");
  }

  disconnect() {
    leave(this.element, "overlay");
  }

  handleProvideCount(event){
    const count = event.detail
    this.selectionCountTarget.innerText = count
  }

  handleUnselectAll(event){
    this.element.remove();
  }

  triggerEvent(eventName, data = {}) {
    const prefix = this.prefixValue
    const event = new CustomEvent(`${prefix}${eventName}`, { detail: data })
    document.dispatchEvent(event)
  }

  selectAll(event) {
    const data = { payload: this.payloadValue, name: this.nameValue }
    this.triggerEvent("DispatchPayload", data);
  }

  toggleAll(event) {
    this.hideableTargets.forEach(target => {
      this.toggleHideable(target)
    })
  }

  toggleHideable(target) {
    if (target.classList.contains("hidden")) {
      target.classList.remove("hidden")
    } else {
      target.classList.add("hidden")
    }
  }

}