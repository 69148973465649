import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  validate(e) {
    let input = e.target;
    input.dataset.realNumber = input.value.replaceAll(".", "");

    if(e.keyCode >= 48 && e.keyCode < 58){
      let number = input.dataset.realNumber;
      input.value = number.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }else{
        e.preventDefault();
      }

  }

  allowOnlyNumbers(e) {
    const key = e.which || e.keyCode;
  
    if (
      (key < 48 || key > 57) && // Digits 0-9
      ![8, 9, 13, 27, 37, 39, 46].includes(key)
    ) {
      e.preventDefault();
      return;
    }
  }

  connect(){
    this.element.addEventListener('keyup', this.validate, false);
    this.element.addEventListener('keydown', this.allowOnlyNumbers, false);
  }

  disconnect(){
    this.element.removeEventListener('keyup', this.validate, false);
    this.element.removeEventListener('keydown', this.allowOnlyNumbers, false);
  }
}
