import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ['amount', 'button', 'buttonHeading'];
   
    async connect(){
        await this.delay(1000); // Wait for 1 second for the turbo frame ot load before getting the amount value
        this.checkAmount(); // check the payment value and enable or keep the buttons disabled
    }

    checkAmount(){
        let amount = this.amountTarget.innerHTML; // Get the value of the  payment
        amount = Number(amount.replace(/[^0-9\.]+/g,""))/100; //convert it to a float
        if(amount > 0){
            this.buttonTarget.classList.remove("btn-disabled");
            this.buttonTarget.classList.add("btn-primary"); // enable the  payment button
            this.buttonHeadingTarget.classList.remove("btn-disabled");
        }
    }


    async delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    }
}
