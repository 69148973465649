import { Controller } from "@hotwired/stimulus"

const VIRTUAL_CARD_ID = 'virtual'
const PHYSICAL_CARD_ID = 'physical'

export default class extends Controller {
  static targets = ["container", "card", "radio"];

  onChange(e) {
    const radioButtons = this.radioTargets
    this.cardTargets.forEach((card, index) => {
      let radioChecked = e.target == card

      card.classList.toggle('active', radioChecked)
      radioButtons[index].checked = radioChecked
    })
    this.containerTarget.classList.toggle('hidden', e.target.id === VIRTUAL_CARD_ID)
    this.containerTarget.querySelectorAll('select, input').forEach(input => {
      input.required = ( e.target.id !== VIRTUAL_CARD_ID &&
        !input.hasAttribute('data-address-number-target') &&
        !input.name == "office_number") &&
        !input.name == "zip_code"
    })
  }
}
