import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["batchCheckbox"]

  connect(){
    console.log("connected with field review address actions controller")
    const allChecked = Array.from(this.checkboxes).every((checkbox) => checkbox.checked);
    if (allChecked) {
      this.batchCheckboxTarget.checked = true;
    }else{
      this.batchCheckboxTarget.checked = false;
    } 
  }

  get labels() {
    return this.element.querySelectorAll('label[data-info="label_approved"].approved');
  }

  get checkboxes() {
    return this.element.querySelectorAll('input[type=checkbox][name="field_review[status]"]');
  }

  async switch() {
    const labels = this.labels;
  
    for (const label of labels) {
      await new Promise(resolve => {
        label.addEventListener('click', () => {
          resolve();
        }, { once: true });
  
        label.click();
      });
    }
  }
  
}   
