import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['row'];

  unfocusRow(event) {
    let index = event.detail.index;
    let row = this.element.querySelector(`[data-cards-accordion-row-index-value="${index}"]`);
    let rowContent = row.querySelector("[data-cards-accordion-target]");
    let arrowUp = row.querySelector("[data-arrow='up']");
    let arrowDown = row.querySelector("[data-arrow='down']");
    this.resetArrows("up");
    this.resetArrows("down");
    if(rowContent.classList.contains("hidden")){
      // we need to open this row, and make sure other rows are closed
      this.rowTargets.forEach((row, index) => {
        row.classList.add("hidden")
      })
      rowContent.classList.remove("hidden")
      arrowUp.classList.remove("hidden")
      arrowDown.classList.add("hidden")
    }else{
      // in this case the row that was click is open, and we need to hide it.
      rowContent.classList.add("hidden");
      arrowUp.classList.add("hidden")
      arrowDown.classList.remove("hidden")
    }
  }

  resetArrows(type){
    let nodeList = this.element.querySelectorAll("[data-arrow]")
    Array.from(nodeList).forEach(function(node, idx) {
      if (node.dataset.arrow === "up") {
        node.classList.add("hidden");
      } else {
        node.classList.remove("hidden");
      }
    });
  }
}
