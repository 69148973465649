import { Controller } from "@hotwired/stimulus";
import { toggle } from "el-transition";

export default class extends Controller {
  static targets = ["dropdown", "parent"];

  connect() {
    // Saving the reference to this anonymous function so it can be removed on disconnect
    this.dropdownListener = (event) => {
      if (!this.isHidden()) {
        this.closeDropdown(event.target);
      }
    };
    window.addEventListener("click", this.dropdownListener);
  }

  disconnect() {
    window.removeEventListener("click", this.dropdownListener);
  }

  display() {
    toggle(this.dropdownTarget, "profile-dropdown");
  }

  hide() {
    if (!this.dropdownTarget.classList.contains("hidden")) {
      toggle(this.dropdownTarget, "profile-dropdown");
    }
  }

  closeDropdown(target) {
    // Close the dropdown target only if element is not contained within the parent;
    // namely, when you click outside of the dropdown menu
    if (!this.parentTarget.contains(target)) {
      toggle(this.dropdownTarget, "profile-dropdown");
    }
  }

  isHidden() {
    return this.dropdownTarget.className.includes("hidden");
  }
}
