import { Controller } from "@hotwired/stimulus"


// Dispatch Custom Event to filtersController
export default class extends Controller {
  connect() {
    // useDispatch(this)
  }

  init() {
    // this.dispatch("init")
  }
}
