import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['content', 'arrow'];
  static values = { index: Number }

  toggle() {
    this.dispatch("toggle", { detail: { index: this.indexValue }} )
  }
}
