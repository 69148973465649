import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "errorMessage"];

  regex = {
    cardName: /[^a-z0-9 ]/i,
    businessCommercialName: /[^a-z0-9 -.]/i,
    name: /[^a-z0-9 ]/i
  }

  connect() {
    console.log("connected! (special_characters_controller.js)");

    this.handleKeyup = this.handleKeyup.bind(this);
    this.inputTarget.addEventListener("keyup", this.handleKeyup.bind(this));
  }

  handleKeyup() {
    const currentValue = this.inputTarget.value;
    const sanitizedValue = currentValue.replace(this.regex[this.inputTarget.dataset.type], "");
    if (currentValue !== sanitizedValue) {
      this.inputTarget.value = sanitizedValue;
      this.errorMessageTarget.classList.remove("hidden");
      setTimeout(() => this.errorMessageTarget.classList.add("hidden"), 1500); 
    }
  }

  containsSpecialCharacter(regex) {
    return this.inputTarget.value.match(regex);
  }

  disconnect() {
    this.inputTarget.removeEventListener("keyup", this.handleKeydown);
  }
}