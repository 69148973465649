import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to data-controller="recaptcha"
export default class extends Controller {
  static values = {
    uncheckedBoxMessage: String
  }

  connect() {
    this.scope.element.addEventListener('submit', this.validateCaptcha.bind(this))
  }

  validateCaptcha(event) {
    const submitBtn = event.target.querySelector('button[type="submit"]')
    const captchaResult = event.target.querySelector('#g-recaptcha-response').value;

    if (captchaResult === '') {
      // Captcha hasn't been verified: Interrupting form submission, enabling submit button, showing alert and resetting captcha
      event.preventDefault();
      
      setTimeout(() => {
        alert(this.uncheckedBoxMessageValue)
        window.grecaptcha.reset();
        Rails.enableElement(submitBtn);
      }, 100)
    }
  }
}
