import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  removeInvalidCharacters(e) {
    let input = e.target;
    input.value = input.value.replace(/[^a-zA-ZñÑ0-9áéíóúÁÉÍÓÚ´\ .-]/g, "").substr(0,23);
  }

  connect(){
    this.element.addEventListener('keyup', this.removeInvalidCharacters, false);
    this.element.addEventListener('keydown', this.removeInvalidCharacters, false);
  }

  disconnect(){
    this.element.removeEventListener('keyup', this.removeInvalidCharacters, false);
    this.element.removeEventListener('keydown', this.removeInvalidCharacters, false);
  }
}
