import { Controller } from "@hotwired/stimulus";
import { enter } from "el-transition";

export default class extends Controller {
  static targets = ["modal"];

  open() {
    enter(this.modalTarget, "overlay");
  }
}
