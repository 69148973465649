import { Controller } from "@hotwired/stimulus"

/**
 * TabContainerController
 *
 * This is a Stimulus.js controller for managing tabbed interfaces.
 *
 * @targets
 * - link: The elements that are used to switch between tabs.
 * - container: The parent element of the tabbed interface.
 */
export default class extends Controller {
  static targets = ['link', 'container'];

  /**
   * The connect method is called when the controller is first instantiated.
   * It sets the first link as the active link and invokes the `changeBasedOnLink` function.
   */
  connect() {
    this.activeLink = this.linkTargets[0];
    this.changeBasedOnLink(this.activeLink);
  }

  /**
   * The change method is invoked when a link is clicked.
   * It prevents the default action of the event and invokes the `changeBasedOnLink` function with the clicked link.
   *
   * @param {Event} evt - The event object.
   */
  change(evt){
    evt.preventDefault();
    this.changeBasedOnLink(evt.target);
  }

  /**
   * The `changeBasedOnLink` method sets the active link, adds the 'active' class to it, and hides all other contents.
   *
   * @param {Element} link - The link that was clicked.
   */
  changeBasedOnLink(link){
    this.hideLinks();
    this.activeLink = link;
    this.activeLink.classList.add('active');
    this.hideContents();
  }

  /**
   * The `hideLinks` method removes the 'active' class from all links.
   */
  hideLinks(){
    this.linkTargets.forEach((link) => {
      link.classList.remove('active');
    });
  }

  /**
   * The `hideContents` method adds the 'hidden' class to all content elements.
   * It also queries the container for the content associated with the active link.
   */
  hideContents(){
    this.containerTarget.querySelectorAll('div.tab').forEach((content) => {
      content.classList.add('hidden');
    });
    this.containerTarget.querySelector(`#${this.activeLink.getAttribute("href")}`).classList.remove('hidden');
  }
}
