import { Controller } from "@hotwired/stimulus";
import { leave, enter } from "el-transition";
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["container"];

  connect(){
    enter(this.containerTarget, 'overlay');
  }

  close() {
    if(this.element.querySelector('[data-controller="modal-container"] [prevent-closing-modal]')){
      return null
    }else{
      leave(this.containerTarget, "overlay");
    }
  }
}
