import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "form"]

  connect(){
    this.loadingListener = (event) => this.replaceWithLoadingSpinner(event)
    this.restoreListener = (event) => this.restoreButtonHTML(event)

    window.addEventListener("turbo:submit-start", this.loadingListener)
    window.addEventListener("turbo:submit-end", this.restoreListener)
  }

  disconnect(){
    window.removeEventListener("turbo:submit-start", this.loadingListener)
    window.addEventListener("turbo:submit-end", this.restoreListener)
  }

  loadingIcon(){
    let loadingIcon = document.createElement("div");
    loadingIcon.classList.add("lds-dual-ring");
    return loadingIcon
  }

  replaceWithLoadingSpinner(event){
    // Replaces the inner HTML of a target element with a loading spinner indicator.
    // The target element must define a `data-loading-spinner` attribute with any truthy value

    if(this.isSameForm(event.target)){
      this.buttonHTML = this.buttonTarget.innerHTML;
      this.buttonTarget.innerHTML = "";
      this.buttonTarget.appendChild(this.loadingIcon());
    }
  }

  restoreButtonHTML(event){

    if(this.isSameForm(event.target)){
      this.buttonTarget.innerHTML = "";
      this.buttonTarget.innerHTML = this.buttonHTML;
    }
  }

  isSameForm(eventTarget){
    return eventTarget === this.formTarget;
  }

}
