import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("filter controller connected")  
  }
  
  submit(evt){
    evt.preventDefault();
    console.log("submit filter form")
    const form = this.element;
    const params = new URLSearchParams();
  
    // Get all form elements
    const formElements = form.elements;
  
    // Filter out elements that have the data-filter-parameter attribute
    const filterElements = Array.from(formElements).filter(element => element.dataset.filterParameter !== undefined && element.type === 'hidden');

    let search = {};
    // For each filtered element, append its value to the FormData object using its name as the key
    filterElements.forEach(element => {
      if (element.value !== null && element.value !== undefined && element.value !== '') {
        search[element.name] = element.value;
      }
    });

    // Only append 'search' to params if it has values
    if (Object.keys(search).length > 0) {
      params.append('search', JSON.stringify(search));
    }
  
    // Use Turbo to perform a GET request
    Turbo.visit(`${form.action}?${params.toString()}`);
  }
}