// app/javascript/controllers/multiple_selection_form_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['form', 'hiddenField']
  static values = { prefix: String }

  connect() {
    this.hideOrshowSubmitButton()
  }

  handleCheckboxSelected(event) {
    const value = event.detail
    if (!this.hasDuplicateFieldValue(value)) {
      this.appendHiddenField(value, "selected_values[]")
    }
  }

  handleCheckboxUnselected(event) {
    const value = event.detail
    this.removeHiddenField(value)
  }

  handleUnselectAll(_event){
    this.hiddenFieldTargets.forEach((hiddenField) => {
      hiddenField.remove()
    })
    this.hideOrshowSubmitButton()
  }

  handleDispatchPayload(event){
    const nameValue = event.detail.name
    const payload = event.detail.payload
    // Check if a hiddenFieldTarget element with the given name value already exists and updates the first instance
    const hiddenField = this.hiddenFieldTargets.find((hiddenField) => hiddenField.name === nameValue)
    if (hiddenField) {
      hiddenField.value = payload
      return
    }

    // If no matching element found, create a new element
    return this.appendHiddenField(payload, nameValue)
  }

  appendHiddenField(elementValue, elementName) {
    const hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = elementName
    hiddenField.value = elementValue
    hiddenField.setAttribute('data-multiple-selection-form-target', 'hiddenField')
    this.formTarget.appendChild(hiddenField)
    this.hideOrshowSubmitButton()
  }

  removeHiddenField(value) {
    this.hiddenFieldTargets.forEach((hiddenField) => {
      if (hiddenField.value === value) {
        hiddenField.remove()
      }
    })
    this.hideOrshowSubmitButton()
  }

  hasDuplicateFieldValue(value) {
    const hiddenFields = this.hiddenFieldTargets
    return Array.from(hiddenFields).some((hiddenField) => hiddenField.value === value)
  }

  triggerEvent(eventName, data) {
    const prefix = this.prefixValue
    const event = new CustomEvent(`${prefix}${eventName}`, { detail: data })
    document.dispatchEvent(event)
  }

  handleRequestCount() {
    const count = this.hiddenFieldTargets.length
    this.triggerEvent("ProvideCount", count)
  }

  hideOrshowSubmitButton() {
    if (this.hiddenFieldTargets.length > 0) {
      this.formTarget.classList.remove('hidden')
    }else{
      this.formTarget.classList.add('hidden')
    }
  }
}
