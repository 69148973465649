import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['master']
  static values = { prefix: String }

  toggleCheckbox() {
    if (this.masterTarget.checked) {
      this.triggerEvent('SelectAll')
    } else {
      this.triggerEvent('UnselectAll')
    }
  }

  triggerEvent(eventName) {
    const prefix = this.prefixValue
    const event = new CustomEvent(`${prefix}${eventName}`)
    document.dispatchEvent(event)
  }
}