import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="city-state"
export default class extends Controller {

  static targets = ['state','city','daneCode'];

  async connect(){
    await this.loadStates()
    let selectedValue = this.stateTarget.options[this.stateTarget.selectedIndex].value
    await this.loadCities(selectedValue)
    await this.changeDaneCodeValue(0)
  }

  async loadStates(stateName){
    try{
      this.removeSelectOptions(this.stateTarget)
      let response = await fetch(`/states.json`)
      let body = await response.json()
      this.addStates(body)
    }catch(error){
      console.log("An error fetching states.", error)
    }
  }

  async loadCities(stateName){
    try{
      this.removeSelectOptions(this.cityTarget)
      let response = await fetch(`/cities.json?state=${stateName}`)
      let body = await response.json()
      this.addCities(body)
    }catch(error){
      console.log("An error fetching cities.", error)
    }
  }

  async onChange(event){
    await this.loadCities(event.target.value)
    this.changeDaneCodeValue(0)
  }

  async onChangeCity(){
    this.changeDaneCodeValue(this.cityTarget.selectedIndex)
  }

  async changeDaneCodeValue(index){
    this.daneCodeTarget.value = ""
    this.daneCodeTarget.value = this.cityTarget.options[index].id
  }

  addStates(states){
    let that = this;
    states.forEach(state => {
      let option = document.createElement("option")
      option.setAttribute("value", state[0])
      option.innerText = state[1]
      if(that.stateTarget.dataset.value === state[0]){
        option.selected = true
      }
      this.stateTarget.appendChild(option)
    })
  }

  addCities(cities){
    let that = this;
    cities.forEach(city => {
      let city_name = city[0]
      let city_code = city[1]
      let option = document.createElement("option")
      option.setAttribute("value", city_name)
      option.setAttribute("id", city_code)
      option.innerText = city_name
      if(that.cityTarget.dataset.value === city_name){
        option.selected = true
      }
      this.cityTarget.appendChild(option)
    })
  }

  removeSelectOptions(parent){
    while(parent.firstChild){
      parent.removeChild(parent.firstChild)
    }
  }

}
