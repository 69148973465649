import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="entity-verification"
export default class extends Controller {

  static targets = ['button', 'finishedMessage', 'incompleteMessage'];

  connect() {
    let button = this.element.querySelector('#mati-button');

    button.addEventListener('mati:loaded', ({ detail }) => {
      console.log('mati loaded')
    });

    button.addEventListener('mati:userFinishedSdk', ({ detail }) => {
      this.buttonTarget.classList.add('hidden');
      this.incompleteMessageTarget.classList.add('hidden')
      this.finishedMessageTarget.classList.remove('hidden')
    });

    button.addEventListener('mati:exitedSdk', ({ detail }) => {
      this.incompleteMessageTarget.classList.remove('hidden')
    });
  }

}
