import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="people-picker-list"
export default class extends Controller {
  static targets = ["checkboxes", "searchInput", "selected"];
  static outlets = ["filter-form"];

  connect() {
    console.log("connected with people picker list controller")
    this.selectedPeople = [];
  }

  clearAll(evt) {
    evt.preventDefault();
    this.checkboxesTargets.forEach((checkbox) => {
      checkbox.checked = false;
    });
    this.selectedTarget.value = "";
    
    if (this.hasFilterFormOutlet) {
      this.filterFormOutlet.submit(evt);
    }
  }
  
  getSelectedCheckboxes() {
    let selectedCheckboxes = [];
    this.checkboxesTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        selectedCheckboxes.push(checkbox.value);
      }
    });
    return selectedCheckboxes;
  }

  checkboxSelected(event) {
    const checkbox = event.target;
    const personId = checkbox.value;
    // if (checkbox.checked) {
    //   this.selectedPeople.push(personId);
    // } else {
    //   const index = this.selectedPeople.indexOf(personId);
    //   if (index !== -1) {
    //     this.selectedPeople.splice(index, 1);
    //   }
    // }
    this.selectedTarget.value = this.getSelectedCheckboxes().join();
  }

  textInput(event) {
    const searchTerm = event.target.value.toLowerCase();
    this.checkboxesTargets.forEach((checkbox) => {
      const personName = checkbox.parentNode.textContent.toLowerCase();
      if (personName.includes(searchTerm)) {
        checkbox.closest("li").style.display = "flex";
      } else {
        checkbox.closest("li").style.display = "none";
      }
    });
  }
}
