import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    

    toggle(e){
      if(e.target.checked){
        document.querySelectorAll("[data-info]").forEach(function(e){
          e.value = e.getAttribute("data-info").replaceAll("*", " ");
        });
      }else{
        // get data values
        document.querySelectorAll("[data-info]").forEach(function(e){
          e.value = "";
        });
      }
    }
}
