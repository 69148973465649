import { Controller } from "@hotwired/stimulus"
import { enter, leave } from 'el-transition';

// this controller makes use of the el-transition functionality
// that allows to add transitions based on a class name.
// https://github.com/mmccall10/el-transition#transition-name-option
export default class extends Controller {
  static targets = ['container', 'offCanvas', 'overlay', "cancel"];

  connect(){
    console.log('Connecting Modal')
    this.removeTimeout = null;
    enter(this.offCanvasTarget, 'rigth-drawer');
    enter(this.overlayTarget, 'overlay');
    // stop the body from scrolling 
    document.body.classList.add('overflow-hidden');
  }

  // Handles the transition ended event.
  transitionEventEnded(event){
    let leaving = false;

    for(let value of event.target.classList.values()) {
      if(value.match(/leave/)){
        leaving = true;
        break;
      }
    }
    if(leaving){
      console.log('removed after leaving')
      clearTimeout(this.removeTimeout);
      this.removeFrame();
    }else{
      console.log('transition ended, I could not find leave');
    }
  }

  disconnect(){
    this.overlayTarget.removeEventListener('transitionend', this.transitionEventEnded.bind(this));
    if(this.removeTimeout){
      this.removeFrame();
    }
  }

  openModal(event){
    let drawerSize = event.target.getAttribute("drawersv") || "max-w-xs";
    this.addDrawerSize(drawerSize)
    this.toggle();
  }

  // hides the drawer, and makes sure to remove the frame contents.
  cancel(event){
    event.preventDefault();
    this.close();
    // remove this in the future, but store the timeoutId so that we can delete
    // it in case the transitionended event is able to pick up this action.
    let that = this;
    this.removeTimeout = setTimeout(() => {
      console.log('removed in timeout')
      that.removeFrame();
    }, 600);
    // Allow the body to scroll again.
    document.body.classList.remove('overflow-hidden');
  }

  close(){
    this.overlayTarget.addEventListener('transitionend', this.transitionEventEnded.bind(this));
    leave(this.offCanvasTarget, 'rigth-drawer');
    leave(this.overlayTarget, 'overlay');
  }

  removeFrame(){
    const turboFrame = document.getElementById("drawer");
    turboFrame.removeAttribute("src");
    while(turboFrame.firstChild){
      turboFrame.firstChild.remove();
    }
  }

  addDrawerSize(size){
    this.offCanvasTarget.classList.add(size);
    this.offCanvasTarget.classList.add("w-screen");
  }

  removeDrawerSize(){
    this.offCanvasTarget.className = "";
  }
}
