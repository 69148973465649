// Reconnect ActionCable after switching accounts

import { Controller } from "@hotwired/stimulus"
import { useDebounce } from 'stimulus-use'


export default class extends Controller {
  static debounces = [
    'save'
  ]
  connect() {
    useDebounce(this, { wait: 500 })
    this.element.querySelectorAll('input, textarea').forEach((element) => {
      element.dataset.action = element.dataset.action + ' keyup->autosave#save'
    })
  }

  async save(event) {
    this.element.parentElement.classList.add('registration-form-section--saving')
    
    const url = new URL(window.location.protocol +"//"+window.location.host +""+ this.element.getAttribute("action"));
    let that = this;

    const data = new FormData(this.element);

    fetch(url, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: data
    })
    .then (response => response.text())
    .then(html => {
      that.element.parentElement.classList.remove('registration-form-section--saving')
      Turbo.renderStreamMessage(html)
    });
  }
}
