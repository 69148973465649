import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  validate(e){
    let input = e.target;
    input.dataset.realNumber = input.value.replace(/[- )(]/g, "");

    // accept only numbers 0 = 47 key code.
    if(e.keyCode >= 48 && e.keyCode < 58){
      let phone = input.dataset.realNumber;
      phone = `(${phone.substr(0,3)}) ${phone.substr(3,3)} - ${phone.substr(6,4)}`;
      input.value = phone;
    }else{
      e.preventDefault();
    }
  }

  revalidateExisting(input){
    input.dataset.realNumber = input.value.replace(/[- )(]/g, "");

    let phone = input.dataset.realNumber;
    phone = `(${phone.substr(0,3)}) ${phone.substr(3,3)} - ${phone.substr(6,4)}`;
    input.value = phone;
  }

  allowOnlyNumbers(e){
    if(e.which >= 65 && e.which <= 90){
      e.preventDefault();
      return;
    }
  }

  connect(){
    this.element.addEventListener('keyup', this.validate, false);
    this.element.addEventListener('keydown', this.allowOnlyNumbers, false);
    if(this.element.value.length > 0){
      this.revalidateExisting(this.element);
    }
  }

  disconnect(){
    this.element.removeEventListener('keyup', this.validate, false);
    this.element.removeEventListener('keydown', this.allowOnlyNumbers, false);
  }
}
