import PasswordVisibility from "stimulus-password-visibility";

export default class extends PasswordVisibility {
  connect() {
    super.connect();
    window.addEventListener("click", this.clickOutside.bind(this));
  }

  clickOutside(e) {
    if (this.element.contains(e.target) === false && this.hidden === false) {
      this.hidePassword();
    }
  }

  hidePassword() {
    this.inputTarget.type = "password";
    this.hidden = !this.hidden;
    this.iconTargets.forEach((icon) => icon.classList.toggle(this.class));
  }

  disconnect() {
    window.removeEventListener("click", this.clickOutside.bind(this));
  }
}
