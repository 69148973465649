import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connectedOptions = [];

  connect() {
    const options = this.element.querySelectorAll('option');

    options.forEach((option) => {
      this.connectedOptions.push(option);
      option.textContent = `${option.textContent} (${option.value})`;
    });
  }

  disconnect() {
    this.connectedOptions.forEach((option, index) => {
      option.textContent = this.connectedOptions[index];
    });
  }
}