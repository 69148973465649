import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.element.classList.add("cursor-pointer");
  }

  goToUrl(event) {
    event.preventDefault();
    Turbo.visit(this.urlValue, { frame: "drawer" });
  }
}
