import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="review-note"
export default class extends Controller {
  static targets = ["public", "private", "actionInfo"];

  connect() {
    console.log("connected with review_note controller")
    this.toggleVisibility();
  }

  onChange(event) {
    this.toggleVisibility();
  }

  toggleVisibility() {
    const selectedOption = this.actionInfoTarget.value;
    const publicTarget = this.publicTarget;
    const privateTarget = this.privateTarget;

    if (selectedOption === "request_changes") {
      publicTarget.classList.remove("hidden");
      privateTarget.classList.add("hidden");
    } else {
      publicTarget.classList.add("hidden");
      privateTarget.classList.remove("hidden");
    }
  }
}
