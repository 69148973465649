import { Controller } from "@hotwired/stimulus"
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

export default class extends Controller {

    static targets = ["pdfCanvas", "currentPage", "pageCount"];

    initialize() {
        // set document 
        this.pdfDoc = null;
        // set page number
        this.pageNum = 1;
        // set page rendering
        this.pageRendering = false;

        // set the scale
        this.scale = 1;

        // get the pdf canvas
        this.pdfCanvas = this.pdfCanvasTarget;

        // get data-file in the canvas 
        this.filePath = this.pdfCanvas.getAttribute("data-file");

        // get data-filename in the canvas 
        this.filename = this.pdfCanvas.getAttribute("data-filename");

        this.ctx = this.pdfCanvas.getContext('2d');
        this.pageNumPending = null;
    }

    async connect() {
        // get the pdf file 
        try {
            // if password is provided, use it to decrypt the PDF file
            if (this.password) {
                const loadingTask = pdfjsLib.getDocument({
                    url: this.filePath,
                    password: this.password,
                });
                this.pdfDoc = await loadingTask.promise;
            } else {
                this.pdfDoc = await pdfjsLib.getDocument(this.filePath).promise;
            }
            // show the number of pages
            this.pageCountTarget.textContent = this.pdfDoc.numPages;
            // render the first page
            this.renderPage(this.pageNum);

        } catch (error) {
            if (error.name === "PasswordException"){
                this.promptForPassword();
            } else {
                console.log(error)
            }
            
        }

    }

    /**
     * Displays next page.
     */
    onNextPage() {
        if (this.pageNum >= this.pdfDoc.numPages) {
            return;
        }
        this.pageNum++;
        this.queueRenderPage(this.pageNum);
    }

    /**
     * Displays previous page.
     */
    onPrevPage() {
        if (this.pageNum <= 1) {
            return;
        }
        this.pageNum--;
        this.queueRenderPage(this.pageNum);
    }

    /**
     * Prompts user for PDF password.
     */
    promptForPassword() {
        const password = prompt("Contraseña:");
        if (password) {
            this.password = password;
            this.connect();
        }
    }

    /**
     * Gets the 
     */
    async download() {
      try{
        const data = await this.pdfDoc.getData();
        const blob = new Blob([data], {
          type: "application/pdf"
        })
        this.downloadBlob(blob)
      } catch(_error){
        alert("No se pudo descargar el PDF. Por favor intente de nuevo")
      }
    }

    downloadBlob(blob){
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = this.filename
      document.body.appendChild(link);

      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', { 
          bubbles: true, 
          cancelable: true, 
          view: window 
        })
      );
    
      // Remove link from body
      document.body.removeChild(link);
    }

    /**
     * If another page rendering in progress, waits until the rendering is
     * finised. Otherwise, executes rendering immediately.
     */
    queueRenderPage(num) {
        if (this.pageRendering) {
            this.pageNumPending = num;
        } else {
            this.renderPage(num);
        }
    }


    /**
     * Get page info from document, resize canvas accordingly, and render page.
     * @param num Page number.
     */
    renderPage(num) {
        this.pageRendering = true;
        // Using promise to fetch the page
        this.pdfDoc.getPage(num).then(page => {
            const viewport = page.getViewport({
                scale: this.scale
            });
            this.pdfCanvas.height = viewport.height;
            this.pdfCanvas.width = viewport.width;

            // Render PDF page into canvas context
            const renderContext = {
                canvasContext: this.ctx,
                viewport: viewport
            };
            const renderTask = page.render(renderContext);

            // Wait for rendering to finish
            renderTask.promise.then(() => {
                this.pageRendering = false;
                if (this.pageNumPending !== null) {
                    // New page rendering is pending
                    this.renderPage(this.pageNumPending);
                    this.pageNumPending = null;
                }
            });

            // Update page counters
            this.currentPageTarget.textContent = num;
        });
    }


}