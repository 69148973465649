import { Controller } from "@hotwired/stimulus";
import * as txml from 'txml/dist/txml.mjs'
export default class extends Controller {
    static targets = [ "display" ]
    connect() {
        const info = this.data.get("xmlValue"); // Get the xml string
        const xml = txml.simplify(txml.parse(info)); // parse the xml
        this.displayTarget.innerHTML = JSON.stringify(xml, null, 2); // formart and display the parsed xml
    }
}
